@import "~bootstrap/scss/bootstrap";
@import "./themes.sass";
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap');

main.App {
	background-color: var(--bg__primary);
	color: var(--color__text);
	font-family: 'Open Sans', sans-serif; }
//commons
img {
	border-radius: 8px; }
.ld--title {
	font-weight: 700;
	font-size: 26px;
	line-height: 24px;
	margin-bottom: 15px;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
	min-height: 24px; }

.ld--card {
	background: var(--bg__card);
	border-radius: 8px;
	padding: 16px;
	width: 100%; }

.ld--btn {
	background: var(--bg__btn);
	color: var(--color__text__btn);
	border-radius: 4px;
	overflow: hidden;
	padding: 0;
	border: none;
	min-height: 44px;
	&:disabled {
		cursor: not-allowed; }
	&.disabled {
		background: var(--bg__btn__disabled); }

	span {
		display: block;
		padding: 10px 16px;
		transition: all ease-in-out .2s;
		font-size: 16px;
		font-weight: 600;
		&:hover {
			background: rgba(#000,.2); } } }

.ld--btn-shop {
	display: block;
	padding: 10px 16px;
	transition: all ease-in-out .2s;
	font-size: 16px;
	border-radius: 4px;
	font-weight: 600;
	border: solid 1px;
	border-color: var(--bg__btn);
	background: none;
	color: var(--bg__btn);
	display: block;
	text-decoration: none;
	width: max-content;
	&:hover {
		color: var(--bg__btn); } }

.ld--btn-help {
	transition: all ease-in-out .2s;
	font-size: 16px;
	border-radius: 4px;
	font-weight: 600;
	min-width: 375px;
	text-align: center;
	background: none;
	background: var(--bg__btn__help);
	display: block;
	text-decoration: none;
	width: max-content;
	margin: 55px auto;
	overflow: hidden;
	border: solid 1px var(--bg__btn);
	span {
		color: var(--bg__btn);
		display: block;
		padding: 10px 16px; }
	&:hover {
		span {
			color: var(--bg__btn);
			background: rgba(#000,.2); } } }
//commons end

.App {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.ld--header {
		display: block;
		min-height: 200px;
		background-color: #D9D9D9;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin-bottom: 32px;
		figure {
			display: block;
			margin-bottom: 0;
			&:last-child {
				display: none; }
			@media (max-width:575px) {
				&:last-child {
					display: block; }
				&:first-child {
					display: none; } }
			img {
				display: block;
				width: 100%;
				display: block;
				border-radius: 0; }
			&.banner-desktop {
				@media (max-width:690px) {
					display: none; } }
			&.banner-movil {
				@media (max-width:690px) {
					display: block; } } }
		.fixed-top {
			left: 0;
			position: absolute;
			top: 0;
			transition: all .2s ease-in-out;
			width: 100%;
			.container {
				display: flex;
				justify-content: space-between;
				padding: 18px 0; }
			svg {
				cursor: pointer; }
			&:hover {
				opacity: 0.7; } } } }

.wrap-countDown {
	display: flex;
	flex-direction: column;
	background: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0,.1);
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 2rem;
	&>p {
		font-size: 16px;
		text-align: center;
		color: #909090; }
	.counters {
		display: flex;
		width: 100%;
		justify-content: center;
		div {
			display: flex;
			flex-direction: column;
			margin: 0 10px;
			span:first-child {
				height: 85px;
				line-height: 85px;
				border-radius: 8px;
				background: #f5f5f5;
				width: 62px;
				margin-bottom: 10px;
				text-align: center;
				font-size: 35px; }
			span:last-child {
				color: var(--color__secondary);
				display: block;
				text-align: center;
				font-size: 12px; } } } }

.theme--dark .wrap-countDown {
	background: var(--bg__card);
	.counters {
		div {
			span:first-child {
				background: var(--bg__btn); } } } }
.ld--intro {
	margin-top: 32px;
	margin-bottom: 32px;
	p {
		font-size: 17px;
		line-height: 22px; } }

.ld--wrap-notas {
	margin-bottom: 32px;
	.ld--title {
		/* color: var(--color__secondary) */ }
	li {
		color: var(--color__secondary);
		span {
			color: var(--color__text); } } }

.notaImportante {
	a {
		color: var(--bg__btn);
		font-size: 18px;
		font-weight: 600; } }
.ld--wrap-funciones {
	margin-bottom: 32px;
	.ld--wrap-botones-titles {
		align-items: center;
		.ld--monedas {
			display: flex;
			margin-bottom: 15px;
			span {
				display: block;
				font-size: 14px;
				font-weight: 600;
				padding: 5px 10px;
				border-radius: 4px;
				line-height: 14px;
				margin-left: 10px;
				cursor: pointer;
				border: solid 1px var(--color__secondary);
				color: var(--color__secondary);
				&.active {
					background: var(--color__secondary);
					color: var(--bg__primary); } } } }
	.ld--card {
		margin-bottom: 32px; }
	.ld--funciones {
		.ld--item-funcion {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0; }
			.ld--equipos {
				display: flex;
				align-items: center;
				min-width: 215px;
				img {
					display: block;
					max-width: 100px;
					&:nth-child(2) {
						width: 100px;
						margin: 0 30px; } }
				.ld--ciudad {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px; } }
			.ld--lugar {
				margin-right: 40px;
				min-width: 200px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				.ld--recinto {
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					line-height: 20px; } }
			.ld--fecha {
				font-size: 15px;
				line-height: 20px;
 }				// color: var(--color__text__light)
			.ld--puertas {
				font-size: 14px;
				line-height: 20px;
				color: var(--color__text__light); }

			.ld--acciones {
				margin-left: 40px;
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				button {
					min-width: 140px; }
				a {
					display: block;
					margin-top: 10px;
					font-weight: 600;
					color: var(--color__secondary); } }

			@media (max-width:992px) {
				flex-wrap: wrap;
				.ld--lugar,.ld--fecha,.ld--acciones, {
					width: 100%;
					margin-right: 0; }

				.ld--recinto {
					text-align: center;
					margin: 30px 0; }
				.ld--fecha {
					margin: 20px 0;
					text-align: center; }
				.ld--equipos {
					justify-content: center;
					width: 100%;
					margin-right: 0; }
				.ld--acciones {
					margin-left: 0;
					button {
						width: 100%; } } } } }
	.ld-det-mapa-prec {
		border-top: solid 1px #F2F2F2;
		img {
			display: block;
			width: 100%; } } }

.ld--wrap-precios {
	display: flex;
	width: 100%;
	min-height: 100%;
	flex-direction: column;
	.ld--precios {
		height: 100%;
		display: flex;
		width: 100%;
		>div {
			width: 100%;
			display: flex;
			flex-direction: column; }
		@media (max-width:767px) {}
		.ld--mapa {
			margin-bottom: 32px;
			display: flex;
			align-items: center;
			figure {
				padding: 50px;
				margin-bottom: 0;
				img {
					display: block;
					max-width: 100%; } } }

		.ld--detalles {
			padding: 0 0 0 56px;
			margin-bottom: 32px;
			height: 100%;
			.ld--item-precio {
				display: flex;
				justify-content: flex-start;
				position: relative;
				padding: 16px;
				padding-left: 0;
				align-items: center;
				&:not(:last-child) {
					border-bottom: solid 1px #F2F2F2; }
				.ld--color {
					position: absolute;
					height: 12px;
					width: 12px;
					border-radius: 50%;
					background: #aaa;
					top: 50%;
					left: -34px;
					transform: translateY(-50%); }
				.ld--info {
					margin-right: 30px;
					.ld--nombre {
						font-size: 17px;
						line-height: 22px;
						font-weight: 500; }
					.ld--descripcion {
						font-size: 15px;
						line-height: 20px;
						color: var(--color__text__light); } }
				.ld--precio {
					margin-left: auto;
					font-size: 17px;
					line-height: 22px;
					font-weight: 500; } } }

		.ld--nota-fees {
			font-size: 13px;
			color: var(--color__text__light); } } }

.ld--snacks {
	margin-bottom: 32px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch; }

.ld--merch {
	margin-bottom: 32px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch; }

.ld--item-shop {
	height: 100%;
	width: 100%;
	display: flex;
	.ld--item-wrap {
		width: 100%;
		height: 100%;
		max-width: 700px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		padding: 10px 20px;
		figure {
			display: block;
			margin-right: 40px;
			img {
				display: block;
				width: 200px;
				margin: 0 auto;
				@media (max-width:1200px) {
					width: 150px; } } } }

	.ld--info-item-shop {
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		.ld--nombre {
			font-size: 22px;
			line-height: 28px;
			font-weight: 700;
			margin-bottom: 20px;
			margin-top: auto; }
		.ld--promo {
			font-size: 17px;
			margin-bottom: 20px;
			line-height: 22px;
			color: var(--color__text__light);
			strong {
				color: var(--color__text); }
			span {
				text-decoration: line-through; } }
		.ld--btn-shop {
			margin-top: auto; } } }
.ld--standing {
	.ld--card {
		margin-top: 32px; }
	.ld--table {
		table {
			width: 100%;
			thead {
				th {
					font-size: 20px;
					padding: 5px 20px;
					text-align: center;
					color: var(--color__secondary); } }
			tbody {
				td {
					padding: 10px;
					font-size: 15px;
					text-align: center; } } } } }
.videoresumen {
	display: flex;
	flex-direction: column;
	iframe {
		border-radius: 14px;
		width: 100%; } }
.ld--wrap-spotify {
	margin-top: 50px; }
.player-wrapper {
	position: relative;
	padding-top: 356px; // 720 / 1280 = 0.5625 */
	border-radius: 12px;
	overflow: hidden; }
.react-player {
	position: absolute;
	top: 0;
	left: 0; }
.ld--patrocinantes {
	padding: 24px 0;
	background: #393939;
	h3 {
		color: #B7B7C2;
		text-align: center;
		font-size: 15px;
		line-height: 20px;
		font-weight: 400; }
	.ld--logos {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		width: 100%;
		justify-content: center;
		align-items: center;
		figure {
			margin-bottom: 20px;
			padding: 0 15px;
			max-width: 164px;
			img {
				max-height: 120px;
				display: block;
				width: 100%; } } } }

.ld--footer {
	margin-top: auto;
	background: var(--bg__footer);
	padding: 20px 0;
	span {
		img {
			max-height: 60px;
			margin-right: 20px; } }
	&>div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.ld--logo-tm {
			margin-left: auto; } } }

// modal
.swal2-modal {
	width: 100% !important;
	max-width: 800px !important;
	.ld--modal-map {
		padding: 15px;
		figure {
			margin-bottom: 0;
			img {
				display: block;
				max-width: 100%; } } } }

.ld--footer {
	margin-top: auto;
	background: #1e1e1e;
	padding: 20px 0;
	img {
		max-height: 60px; }
	&>div {
		display: flex;
		justify-content: space-between;
		.ld--logo-tm {
			max-height: 70px;
			@media (min-width:768px) {
				margin-left: auto; } } } }

.border-radius-10 {
	border-radius: 10px; }

.mb-0 {
	margin-bottom: 0px !important; }

.swal2-html-container {
	margin: 0 !important; }
.swal2-popup {
	padding: 5px !important; }
