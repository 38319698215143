@import "./themes.sass";

a {
    text-decoration: none !important; }

#pageContactUs {
    background-color: var(--bg__primary);
    color: var(--color__text);
    font-family: 'Open Sans', ans-serif;
    min-height: 100vh;
    overflow: hidden;
    .back-arrow {
        color: #000; }

    .logo {
        img {
            display: block;
            width: 100%;
            max-width: 80px;
            margin: 20px 0 50px; } }

    .title {
        max-width: 500px;
        font-weight: 500;
        font-size: 35px;
        line-height: 45px;
        img {
            width: 55px; } }

    .opciones {
        margin: 50px 0;
        .card {
            flex-direction: row;
            min-height: 140px;
            margin-bottom: 40px;
            border-radius: 2px;
            border-color: transparent;
            box-shadow: 0 0 10px rgba(#000, 0.1);
            figure {
                padding: 0 20px;
                margin-bottom: 0;
                svg {
                    color: var(--bg__btn); } }

            .content {
                padding: 10px 15px;
                h3 {
                    color: var(--color__text__light);
                    font-size: 18px;
                    display: block;
                    margin-bottom: 0; } }

            &:hover {
                background: var(--bg__btn);
                box-shadow: 0 0 10px rgba(#000, 0.2);
                figure {
                    svg {
                        color: #fff; } } } }

        & {
            h3 {
                color: #fff; } } }

    .contacto {
        width: 100%;
        margin: 50px auto 50px;
        max-width: 1080px;
        .title {
            max-width: 700px;
            font-size: 30px; }

        form {
            display: block;
            width: 100%;
            max-width: 500px;
            margin-top: 50px;
            .wrapInput {
                width: 100%;
                position: relative;
                margin-bottom: 30px;
                .inputCus {
                    width: 100%;
                    display: block;
                    border: none;
                    border-bottom: solid 1px rgba($color: #fff, $alpha: 0.4);
                    color: var(--color__text__light);
                    background: none;
                    outline: none !important;
                    box-shadow: none;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 0;
                    &::placeholder {
                        color: rgba($color: #fff, $alpha: 0.5); } }

                .areaCus {
                    width: 100%;
                    display: block;
                    border: none;
                    border-bottom: solid 1px rgba($color: #fff, $alpha: 0.4);
                    color: #000;
                    background: none;
                    outline: none !important;
                    box-shadow: none;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 0;
                    height: 100px;
                    resize: none;
                    &::placeholder {
                        color: rgba($color: #fff, $alpha: 0.5); } }

                .error {
                    color: #f00;
                    font-size: 12px;
                    position: absolute;
                    top: 100%; } }

            button {
                background: var(--bg__btn);
                color: #fff;
                display: block;
                width: 100%;
                margin-top: 50px;
                height: 48px;
                cursor: pointer;
                font-size: 20px; }

            .iti {
                display: block;
                width: 100%;
                color: var(--color__text__light);
                .inputCus {
                    padding-left: 60px; } }

            .iti--separate-dial-code .iti__selected-dial-code {
                font-size: 20px;
                font-weight: 600; } } }

    .itemSelected {
        margin: 50px auto 50px;
        max-width: 1080px;
        h3 {
            font-size: 30px;
            display: flex;
            align-items: center;
            img {
                display: block;
                width: 50px;
                margin-left: 10px; } }

        .back {
            width: 24px;
            margin-bottom: 50px; }

        .faqs {
            width: 100%;
            max-width: 600px;
            margin-top: 70px;
            .faq {
                border-top: solid 1px #919baf;
                padding: 20px 0;
                .titleF {
                    font-size: 18px;
                    font-weight: 600;
                    cursor: pointer;
                    padding-right: 20px;
                    position: relative;
                    .angle {
                        width: 15px;
                        position: absolute;
                        right: 0;
                        top: 0px;
                        &.active {
                            transform: rotate(-180deg); } } }

                .content {
                    color: var(--color__text);
                    margin-top: 20px;
                    font-size: 16px;
                    display: none;
                    a {}

                    &.active {
                        display: block; }

                    ul {
                        padding-left: 14px;
                        margin-top: 10px; } } } }

        .colorRed {
            color: #f00; } } }

.dark {
    #pageContactUs {
        background: #000;
        color: #fff;
        .back-arrow {
            color: #fff; }

        .contacto {
            .inputCus {
                width: 100%;
                border-bottom: solid 1px rgba($color: #fff, $alpha: 0.4) !important;
                color: #fff !important;
                &::placeholder {
                    color: rgba($color: #fff, $alpha: 0.5) !important; } }

            .areaCus {
                border-bottom: solid 1px rgba($color: #fff, $alpha: 0.4) !important;
                color: #fff !important;
                &::placeholder {
                    color: rgba($color: #fff, $alpha: 0.5) !important; } } } } }

.iti--separate-dial-code .iti__selected-flag {
    background: none !important; }

.bg-btn-ppal {
    background: var(--bg__btn) !important;
    outline: none !important; }

.titleCode {
    font-weight: 500;
    font-size: 30px;
    color: var(--color__text); }

.confirmCode {
    background: red !important; }

#wrapCorreoCompra {
    margin-top: 10px;
    input {
        border: 1px solid var(--color__text__light);
        display: block;
        width: 100%;
        border-radius: 4px;
        line-height: 38px;
        margin-bottom: 30px;
        outline: none !important;
        box-shadow: none !important;
        padding: 0 10px; }

    #wrap-sec {
        span {
            font-size: 14px;
            color: #f00;
            margin-bottom: 20px; } } }
