@import '../../styles/themes.sass';

#modalMap {
	color: #000;
	position: fixed;
	overflow: auto;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: block;
	padding: 0 30px;

	@media (max-width: 680px) {
		padding: 0 15px;
	}

	z-index: 9999;

	.overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
		background: rgba(#000, 0.7);
	}

	.labelAgotado {
		z-index: 99999;
		transform: translateX(-50%);
		font-family: H-B;
		color: rgb(120, 120, 120);
	}

	.nameZona {
		z-index: 99999;
		transform: translateX(-50%);
		font-family: 'H-B';
		letter-spacing: 0.02em;
		color: #fff;
		font-size: 28px;
		pointer-events: none;

		@media (max-width: 680px) {
			font-size: 18px;
		}

		small {
			font-size: 18px;
			letter-spacing: 0;

			@media (max-width: 680px) {
				font-size: 14px;
			}
		}
	}

	svg {
		width: 100% !important;
		max-width: 600px !important;
		margin-left: auto;
		margin-right: auto;
	}

	.active {
		opacity: 0.8;
	}
}

#modalContent {
	z-index: 10;
	display: block;
	position: relative;
	padding: 30px;
	margin: 30px auto;
	background: #fff;
	border-radius: 10px;
	width: 100%;
	max-width: 710px;

	h3 {
		color: #000;
	}

	@media (max-width: 680px) {
		padding: 30px 10px;
	}

	@media (max-width: 992px) {
		max-width: 710px;
	}

	@media (max-width: 767px) {
		max-width: 510px;
	}

	.cerrar {
		position: absolute;
		right: 0px;
		top: 0px;
		padding: 10px;
		cursor: pointer;
	}

	.infoSec {
		position: absolute;
		left: 30px;
		bottom: 20px;

		&>div {
			font-size: 16px;

			b {
				display: block;
				margin-right: 10px;
			}
		}
	}

	#scroll-svg {
		width: 100%;
		overflow-x: auto;
	}
}

#mapaC {
	width: 100%;
	display: block;
	min-width: 600px;
	max-height: 800px;
}





#TODOSLOSVERDES,
#GOLD,
#MESASEE2,
#TANDING {
	cursor: pointer;
}

#DELOSBESOSQUETEDIRIGHT,
#NOTECONTARONMALRIGHT,
#AMORTOXICORIGHT,
#DELOSBESOSQUETEDILEFT,
#NOTECONTARONMALLEFT,
#AMORTOXICOLEFT,
#BOTELLATRASBOTELLALEFT,
#BOTELLATRASBOTELLARIGHT,
#ADIOSAMORLEFT,
#TEFALLELEFT,
#ADIOSAMORRIGHT,
#TEFALLERIGHT,
#GRADASLATERALESRIGHT,
#GRADASLATERALESLEFT,
#GRADASCENTRALES,
#STANDING {
	cursor: pointer;
}

// *********************************************************************


#mapaC {
	.cls-1{fill:#f2f2f2;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}
	.cls-2{fill:#363636;}
	.cls-3{fill:#30d296;}
	.cls-4{fill:#54ba7b;}
	.cls-5{fill:#1fab54;}
	.cls-6{fill:#179961;}
	.cls-7{fill:#038a1f;}
	.cls-8{fill:#1b1464;}
	.cls-9{fill:#f7bb0d;}
	.cls-10{fill:#f6851a;}
	.cls-11{fill:#ebd077;}
	.cls-12{fill:#840000;}
	.cls-13{isolation:isolate;}
	.cls-14{fill:#fff;}
}





#mapaV {
	.st0{fill:#D84800;}
	.st1{fill:#00007A;stroke:#CCCCCC;stroke-miterlimit:10;font-weight: bold;}
	.st2{fill:#7DBC7D;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;font-weight: bold;}
	.st3{fill:#179961;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;font-weight: bold;}
	.st4{fill:#048A1F;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;font-weight: bold;}
	.st5{fill:#2FBC61;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;font-weight: bold;}
	.st6{fill:none;stroke:#CCCCCC;stroke-miterlimit:10;font-weight: bold;}
	.st7{fill:#5700B6;font-weight: bold;}
	.st8{fill:#363636;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;font-weight: bold;}
	.st9{fill:#FFFFFF;font-weight: bold;}
	.st10{fill:#F6861C;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;font-weight: bold;}
	.st11{fill:#F7BB0D;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;font-weight: bold;}
}