#waitingL {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
        width: 100%;
        max-width: 600px;
        min-height: 600px;
        background: #fff;
        color: 000;

        text-align: center;
        color: #000 !important;
        font-family: 'Open Sans';
        border-radius: 6px;
        overflow: hidden;
        .wrapContent {
            padding: 40px;
            .colaIcon {
                display: block;
                margin: 0 auto 25px;
                max-width: 100px;
            }
            h1 {
                font-size: 20px;
            }
            h2 {
                font-size: 24px;
                font-weight: 700;
                line-height: 36px;
                margin: 10px auto;
            }
            i {
                font-weight: 400;
                font-size: 16px;
                font-style: italic;
                display: block;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
            }
        }

        .footerCola {
            background: #f1f2f4;
            padding: 15px 20px;
            text-align: left;
            p {
                font-size: 14px;
                text-align: center;
                margin-bottom: 15px;
            }
            .changeCurrencyWrap {
                display: flex;
                align-items: center;
                justify-content: center;
                strong{
                    margin-right: 15px;
                }
                .toggleComponent{
                    display: flex;
                    align-items: center;
                    p{
                        margin-bottom: 0;
                        font-size: 16px;
                    }
                    .wrapperTogle {
                        position: relative;
                        height: 34px;
                        width: 80px;
                        border-radius: 17px;
                        background: #919baf;
                        margin: 0 10px;
                        cursor: pointer;
                        span {
                            width: 24px;
                            height: 24px;
                            background: #fff;
                            display: block;
                            position: absolute;
                            border-radius: 50%;
                            top: 5px;
                            left: 5px;
                            transition: ease-in-out all 0.2s;
                        }
                        &.right {
                            span {
                                margin-left: 45px;
                            }
                        }
                    }
                }
            }
            .paymentMethods {
                span {
                    font-size: 12px;
                }
                div {
                    display: flex;
                    img {
                        margin-right: 10px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        //medias queries
        @media (max-width: 767px) {
            max-width: 100%;
            min-height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
